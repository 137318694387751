#app {
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;
}

#nav {
  flex: 0 0 200px;
  border-right: 3px solid var(--color-primary);
}

#content {
  flex: 1 1 100%;
  padding: var(--grid-gutter);
}

@media (max-width: 960px) {
  #app {
    flex-wrap: wrap;
  }

  #nav {
    flex: 0 0 100vw;
    border-right: none;
    border-bottom: 3px solid var(--color-primary);
  }
}
