.frame {
  height: 100%;
  width: auto;
  border: 3px solid var(--color-primary);
  border-radius: 8px;
  overflow: hidden;
}

.map {
  width: 100%;
  height: 100%;
}
